<!-- Start of Selection -->
<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{'image-loading': !bg1ImageLoaded}">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" :removeMenu="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section v-if="bg1ImageLoaded" class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  {{ $t('MOM Provider - AI powered booking management software') }}
                </h1>
              </div>
              <div class="col-12 px-md-3">
                <p>
                  {{ $t('AI powered Simple, flexible and powerful booking software for your business') }}. {{ $t('The easiest way to start, run and grow your online business') }}. {{ $t('Create your profile, update the service you provide and share with your customers instantly within 60 secs') }}.
                </p>
              </div>
              <div class="col-12">
                <b-link 
                  class="custom-design custom-design-black px-md-5 font-weight-bold tra-black-bg" 
                  :href="`https://www.jvzoo.com/b/0/400462/99`"
                >
                  <img src="https://i.jvzoo.com/0/400462/99" alt="MOM Provider App" border="0" width='1' height='1' /> {{ $t('BUY NOW') }}
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase">{{ $t('Welcome to MOM Provider App - Your Gateway to Expanding Your Service Business') }}.</h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="`${$apiUrl}/images/landing/Website/MOM Service.webp`" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div>
                <p>{{ $t("Instant Visibility: Join our platform and instantly showcase your services to a vast online audience") }}.</p>
                <p>{{ $t("Effortless Booking: Simplify service booking with our intuitive interface") }}. {{ $t("Customers can book your services in a few clicks") }}, {{ $t("thanks to our user-friendly design") }}.</p>
                <p>{{ $t("Local & Global Reach: Reach customers not only in your local area but also expand your business globally") }}. {{ $t("Our platform connects you with potential clients from around the world") }}.</p>
                <p>{{ $t("Secure Payments: Worried about payments? MOM Provider App ensures secure and hassle-free transactions") }}. {{ $t("Funds directly deposited into your company account") }}.</p>
                <p>{{ $t("Data-Driven Growth: Make informed decisions with our powerful analytics tools") }}. {{ $t("Track performance") }}, {{ $t("analyze trends") }}, {{ $t("and refine your services for maximum profitability") }}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Create an online profile in just 60 seconds, in 3 easy steps') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img
                        fluid
                        :src="`${$apiUrl}/images/landing/provider/${item.img}`"
                        :alt="item.imgAlt"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <b-link class="custom-design" href="#">{{ $t(item.title) }}</b-link>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="`${$apiUrl}/images/landing-provider/${menu.image}`" class="home_img" :alt="menu.title" />
          </div>
          <b-link class="ai_widget_label custom-design mx-auto" href="#">{{ $t(menu.title) }}</b-link>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Why choose MOM to create your profile?') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>
              {{ $t("0% Commission: MOM Provider App is dedicated to supporting service providers without taking any commission from their earnings") }}. {{ $t("This means you keep 100% of what you earn") }}, {{ $t("helping you maximize your profits") }}.
            </li>

            <li>
              {{ $t("Analytics & Reports: Gain valuable insights into your business performance with our robust analytics and reporting tools") }}. {{ $t("Track key metrics") }}, {{ $t("understand customer behavior") }}, {{ $t("and make data-driven decisions to optimize your services and grow your revenue") }}.
            </li>

            <li>
              {{ $t("Appointment Scheduling: Streamline your operations with our intuitive appointment scheduling feature") }}. {{ $t("Easily manage bookings") }}, {{ $t("set availability") }}, {{ $t("and provide your customers with a convenient way to schedule your services") }}.
            </li>

            <li>
              {{ $t("Agent Management: Efficiently assign agents to customer locations") }}, {{ $t("ensuring timely service delivery") }}. {{ $t("MOM Provider App allows you to oversee your workforce effectively and optimize agent allocation for improved customer satisfaction") }}.
            </li>

            <li>
              {{ $t("Service at Customer Location: Meet your customers where they are") }}. {{ $t("With the MOM Provider App") }}, {{ $t("you can offer services directly at your customer's location") }}, {{ $t("enhancing convenience and accessibility") }}.
            </li>

            <li>
              {{ $t("Direct Payment to Bank: Say goodbye to payment delays") }}. {{ $t("We facilitate direct payments to your bank account") }}, {{ $t("ensuring you receive your earnings promptly and securely") }}.
            </li>

            <li>
              {{ $t("Booking Dashboard: Stay organized with a user-friendly booking dashboard") }}. {{ $t("Manage your appointments") }}, {{ $t("track upcoming services") }}, {{ $t("and access customer information all in one place") }}.
            </li>

            <li>
              {{ $t("Promotion and Loyalty Programs: Boost your business with our promotion and loyalty program features") }}. {{ $t("Create enticing offers") }}, {{ $t("coupons") }}, {{ $t("and loyalty rewards to attract and retain customers") }}, {{ $t("driving growth and customer satisfaction") }}.
            </li>

            <li>
              {{ $t("Cloud POS (Point of Sale): Simplify your transactions with our Cloud POS system") }}. {{ $t("Accept payments seamlessly") }}, {{ $t("generate receipts") }}, {{ $t("and manage your finances efficiently") }}, {{ $t("all from one platform") }}.
            </li>

            <li>
              {{ $t("Automated Invoicing: Save time and reduce administrative burdens with automated invoicing") }}. {{ $t("Generate invoices for your services effortlessly") }}, {{ $t("ensuring accuracy and professionalism in your billing process") }}.
            </li>

            <li>
              {{ $t("Global Payment Support: Expand your reach beyond borders with our global payment support") }}. {{ $t("Accept payments with multiple providers like Stripe") }}, {{ $t("PayPal") }}, {{ $t("Phonepe") }}, {{ $t("Razorpay in multiple currencies") }}, {{ $t("catering to customers worldwide and unlocking new revenue opportunities") }}.
            </li>

            <li>
              {{ $t("The MOM Provider App is designed to empower service providers with a comprehensive suite of tools and features") }}. {{ $t("By offering 0% commission") }}, {{ $t("powerful analytics") }}, {{ $t("and a seamless booking experience") }}, {{ $t("we aim to help you grow your business") }}, {{ $t("increase your revenue") }}, {{ $t("and thrive in today's competitive market") }}.
            </li>
          </ol>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat, imgLoad } from '@/utilities'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  data: () => ({
    bg1ImageLoaded: false,
  }),
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'providerSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    servicesList: {
      get(){
        return [
          {
            title: 'Handyman',
            image: 'handyman.webp',
            url: '/user/store/list/restaurant',
          },
          {
            title: 'Photographer',
            image: 'photographer.webp',
            url: '/user/store/list/grocery',
          },
          {
            title: 'Repair Service',
            image: 'repair-service.webp',
            url: '/user/services/subcategories/list/salon',
          },
          {
            title: 'Laundry',
            image: 'laundry.webp',
            url: '/user/store/list/market',
          },
          {
            title: 'Cleaning Service',
            image: 'cleaning-service.webp',
            url: '/user/store/list/gifts',
          },
          {
            title: 'Salon & Spa',
            image: 'salon-spa.webp',
            url: '/user/services/subcategories/list/repairs',
          },
        ]
      }
    },
    landingItems: {
      get(){
        return [
          {
            img: 'step1.png',
            imgAlt: "Step 1",
            title: this.$t('STEP 1'),
            text: [
              `<strong class="text-light">
                ${this.$t('Register your profile')}
              </strong>
              - ${this.$t('Enter your business name, logo, address & other details and get your custom profile link ready in 60 seconds! with option to schedule booking at customer/provider/online from your profile')}.`
            ]
          },
          {
            img: 'step2.png',
            imgAlt: "Step 2",
            title: this.$t('STEP 2'),
            text: [
              `<strong class="text-light">
                ${this.$t('Update your services')}
              </strong>
              - ${this.$t('Update the services you provide, set prices, add if you provide service at your location, customer location or online with your available timeslot and allow your customers to book instantly')}.`
            ]
          },
          {
            img: 'step3.png',
            imgAlt: "Step 3",
            title: this.$t('STEP 3'),
            text: [
              `<strong class="text-light">
                ${this.$t('Go Online')}
              </strong>
              - ${this.$t('This is where it all begins')}. ${this.$t('Your profile is ready for the world')}. ${this.$t('Share the link with all your friends & family via Email, WhatsApp or Facebook and start taking bookings')}.`
            ]
          }
        ]
      }
    },
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    isEmpty, dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-provider-registration'
      })
    }
  },
  async mounted() {
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/provider-landing-main.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })

    if (!localStorage.getItem('providerSelectedCountryId')) {
      try {
        const ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        if(!isEmpty(ipInfo?.location)){
            if(!this.isSubscriptionCountryLoaded){
              await this.getCountries().then(data => {
                if (data._statusCode === 200) {
                    const countries = data.responseData
                    const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
                    if (isCountryExist) {
                      localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
                    }
                }
              })
            }else{
              const countries = this.countries
              const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('providerSelectedCountryId', isCountryExist.id)
              }
            }
          }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
        this.getSubscriptionPackages()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/bg-images/provider-landing-main.webp');
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
<!-- End of Selection -->
